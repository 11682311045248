/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Scale } from '@mui/icons-material';

const pages = [
    { name: 'Home', link: '/' },
    { name: 'About', link: '/about' },
    // { name: 'Consultation', link: '/consultation' },
    // { name: 'Training', link:'/training' },
    { name: 'Contact', link: '/contact' }
];

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [selectedLink, setSelectedLink] = useState('/');
    const location = useLocation();


    const handleActiveLinkChange = (page) => {
        handleCloseNavMenu();
        setSelectedLink(page);
    };

    useEffect(() => {
        handleActiveLinkChange(location.pathname);
    }, [location]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar sx={{ position: { xs: 'sticky', md: 'static' } }} className='gradient'>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* icon here */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'var(--heading-family)',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
            Training With Jill
                    </Typography>
                    {/* Hamburger Brand Name */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'var(--heading-family)',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
            Training With Jill
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                        <IconButton
                            size="large"
                            aria-label="hamburger menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{ transform: { xs: 'scale(1.6)', md: 'scale()1.3' } }}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' }
                            }}
                        >
                            {pages.map((page) => (
                                <Link to={page.link} key={page.link}>
                                    <MenuItem selected={page.link === selectedLink}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                    {/* Icon Here */}

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                        {pages.map((page) => (
                            <Button
                                onClick={handleCloseNavMenu}
                                id='navlink'
                                component={NavLink}
                                to={page.link}
                                key={page.link}
                            >
                                <Typography
                                    textAlign="center"
                                    fontFamily='var(--heading-family)'
                                    color={'white'}
                                    fontSize={18}
                                    sx={
                                        selectedLink === page.link
                                            ? { textDecoration: 'underline', textUnderlineOffset: '4px' }
                                            : { textDecoration: 'none' }
                                    }
                                >
                                    {page.name}
                                </Typography>
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Navbar;
