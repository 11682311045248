import Homepage from './Home';
import AboutPage from './About';
import ConsultationPage from './Consultation';
import ContactPage from './Contact';
import TrainingPage from './Training';
import Page404 from './Page404';
import { Helmet } from 'react-helmet-async';


export const Home = () => {
    return (
        <>
            <Helmet>‍
                <title>Training With Jill • Balanced Mind, Body, and Soul</title>‍
                <meta name="title" content="Training With Jill • Balanced Mind, Body, and Soul" />
                <meta name="description" content="Training With Jill & Performance Art is a group of multidisciplinary fire dancers based in St Petersburg and Tampa Bay, Florida. Offering fire dancing, fire eating, acrobatics, LED performances, and combination packages. Training With Jill performs at corporate events, weddings, private events and more." />
                <meta property="og:title" content="Training With Jill • Balanced Mind, Body, and Soul" />
                <meta property="og:description" content="Training With Jill & Performance Art is a group of multidisciplinary fire dancers based in St Petersburg and Tampa Bay, Florida. Offering fire dancing, fire eating, acrobatics, LED performances, and combination packages. Training With Jill performs at corporate events, weddings, private events and more." />
                <meta property="og:url" content="https://trainingwithjill.org" />
                <meta property="og:site_name" content="Training With Jill & Performance Art" />
            </Helmet>
            <Homepage />
        </>
    );
};

export const About = () => {
    return (
        <>
            <Helmet>‍
                <title>About | Training With Jill</title>‍
                <meta name="title" content="About | Training With Jill" />
                <meta name="description" content="Training With Jill is a group of fire dancers, LED dancers, acrobats, and movement professionals in St Petersburg, Florida. We are available for corporate events, weddings, and private events throughout Florida and beyond." />
                <meta property="og:title" content="About • Training With Jill" />
                <meta property="og:description" content="Training With Jill is a group of fire dancers, LED dancers, acrobats, and movement professionals in St Petersburg, Florida. We are available for corporate events, weddings, and private events throughout Florida and beyond." />
                <meta property="og:url" content="https://trainingwithjill.org/about" />
                <meta property="og:site_name" content="About • Training With Jill" />
            </Helmet>
            <AboutPage />
        </>
    );
};

export const Contact = () => {
    return (
        <>
            <Helmet>‍
                <title>Contact | Training With Jill</title>‍
                <meta name="title" content="Contact | Training With Jill" />
                <meta name="description" content="Contact Training With Jill & Performance Art for a quote for fire dancers, LED dancers, acrobatics and more! Serving St Petersburg, Florida and the Tampa Bay area. Available for hire throughout Florida and beyond!" />
                <meta property="og:title" content="Contact • Training With Jill" />
                <meta property="og:description" content="Contact Training With Jill & Performance Art for a quote for fire dancers, LED dancers, acrobatics and more! Serving St Petersburg, Florida and the Tampa Bay area. Available for hire throughout Florida and beyond!" />
                <meta property="og:url" content="https://trainingwithjill.org/contact" />
                <meta property="og:site_name" content="Contact • Training With Jill" />
            </Helmet>
            <ContactPage />
        </>
    );
};

export const Consultation = () => {
    return (
        <>
            <Helmet>‍
                <title>Consultation | Training With Jill</title>‍
                <meta name="title" content="Consultation | Training With Jill" />
                <meta name="description" content="Training With Jill & Performance Art offers fire dancing, fire eating, LED/glow performances, dance performers, and acrobats. Serving Tampa Bay FL, St. Petersburg FL, and beyond." />
                <meta property="og:title" content="Services • Training With Jill" />
                <meta property="og:description" content="Training With Jill & Performance Art offers fire dancing, fire eating, LED/glow performances, dance performers, and acrobats. Serving Tampa Bay FL, St. Petersburg FL, and beyond." />
                <meta property="og:url" content="https://trainingwithjill.org/services" />
                <meta property="og:site_name" content="Services • Training With Jill" />
            </Helmet>
            <ConsultationPage />
        </>
    );
};

export const Training = () => {
    return (
        <>
            <Helmet>‍
                <title>Training | Training With Jill</title>‍
                <meta name="title" content="Services | Training With Jill" />
                <meta name="description" content="Training With Jill & Performance Art offers fire dancing, fire eating, LED/glow performances, dance performers, and acrobats. Serving Tampa Bay FL, St. Petersburg FL, and beyond." />
                <meta property="og:title" content="Services • Training With Jill" />
                <meta property="og:description" content="Training With Jill & Performance Art offers fire dancing, fire eating, LED/glow performances, dance performers, and acrobats. Serving Tampa Bay FL, St. Petersburg FL, and beyond." />
                <meta property="og:url" content="https://trainingwithjill.org/services" />
                <meta property="og:site_name" content="Services • Training With Jill" />
            </Helmet>
            <TrainingPage />
        </>
    );
};

export const NotFound = () => {
    return (
        <>
            <Helmet>‍
                <title>Error Page Not Found</title>‍
                <meta name="title" content="Error Page Not Found" />
                <meta name="description" content="404 error page not found" />
                <meta property="og:title" content="Error Page Not Found" />
                <meta property="og:description" content="404 error page not found" />
            </Helmet>
            <Page404 />
        </>
    );
};
