import { Box, Grid } from '@mui/material';
import facebook from '../assets/images/footer/minified/fb-icon.png';
import instagram from '../assets/images/footer/minified/insta-icon.png';

const Footer = () => {
    const getYear = () => new Date().getFullYear();

    return (
        <footer id="footer" className='gradient-reverse'>
            <Grid container justifyContent={'space-between'} p={1}>
                <Grid item>
                    <Box>
                        <h5 className="footer-text">
                            {`Balanced Body © ${getYear()}`}
                        </h5>
                        <Box className="signature">
                            <span>
                                <a
                                    href="https://anthonygress.dev"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                        Built by Anthony
                                </a>
                            </span>
                        </Box>
                    </Box>
                </Grid>
                <Grid item pr={1}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent='flex-end' height={'100%'}>
                        <Grid display={'flex'} alignItems='center' gap={1}>
                            <Box>
                                <a
                                    href="https://facebook.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>
                                        <img
                                            src={facebook}
                                            alt="facebook logo"
                                            height="32"
                                            width="32"
                                        />
                                    </span>
                                </a>
                            </Box>
                            <Box>
                                <a
                                    href="https://www.instagram.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>
                                        <img
                                            src={instagram}
                                            alt="instagram logo"
                                            height="38"
                                            width="38"
                                        />
                                    </span>
                                </a>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;
