import { Box, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import image404 from '../assets/images/minified/image-404.jpg';

const Page404 = () => {

    return (
        <section>
            {/* <h1 className="center">Page Not Found</h1> */}
            <Grid container>
                <Box mt={5} className='center' sx={{ maxHeight: '50vh' }}>
                    <img src={image404} alt="404 island image" className='img-404'/>
                </Box>
            </Grid>
            <Box mt={5}>
                <h2 className="center">Go back to the homepage?</h2>
                <Box className="center" mt={5} width={'100%'}>
                    <Link to='/'>
                        <Button variant='outlined' size='large' sx={{ minWidth: { xs: '75vw', md: '20vw' } }}>
                            {'Home'}
                        </Button>
                    </Link>
                </Box>
            </Box>
        </section>
    );
};

export default Page404;
