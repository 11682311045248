/* eslint-disable no-unused-vars */
import { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import contactImg from '../assets/images/minified/jill-dancer.jpeg';
import ConsultationPage from './Consultation';


const ContactPage = () => {
    // const form = useRef();
    // const [verified, setVerified] = useState(false);
    // const onVerify = () => {
    //     setVerified(true);
    // };


    // const sendEmail = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const formData = new FormData(form.current).entries();

    //         await fetch('/.netlify/functions/email-domain', {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             mode: 'cors',
    //             body: JSON.stringify(Object.fromEntries(formData))
    //         });

    //         Swal.fire({
    //             customClass: {
    //                 title: 'swal2-title'
    //             },
    //             title: 'Message Sent!',
    //             text: 'Thank you for your message! We will get back to you shortly you via email',
    //             icon: 'success',
    //             confirmButtonText: 'Ok!'
    //         });
    //     } catch (error) {
    //         Swal.fire({
    //             customClass: {
    //                 title: 'swal2-title'
    //             },
    //             title: 'Whoops!',
    //             text: 'Something went wrong.',
    //             icon: 'error',
    //             confirmButtonText: 'Ok'
    //         });
    //     }
    //     e.target.reset();
    // };
    return (
        <section>
            <Box xs={12}>
                <Box className='center'>
                    <h1>
                                Contact Us
                    </h1>
                </Box>
                <Box mb={5}>
                    <div className="center">
                        <h4>
                            {
                                'To schedule a consultation, please fill out the form below'
                            }
                        </h4>
                    </div>
                </Box>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ m: { xs: 2, md: 5 } }} className='center'>
                            <img src={contactImg} alt="dancer pose" height="auto" width="80%" />
                        </Box>
                    </Grid>

                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={12} md={6}>
                        <ConsultationPage />
                    </Grid>
                </Grid>
            </Box>

        </section>
    );
};

export default ContactPage;
