/* eslint-disable no-unused-vars */
import { Box, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const TrainingPage = () => {

    return (
        <section>
            <Box className="iframeContainer">
                <iframe
                    title="schedule"
                    src="https://momence.com/TrainWithJill/appointment-reservation/11176?boardId=1494&venueId=2073"
                    style={{ width: '100%', minHeight: '1000px' }}>
                </iframe>
            </Box>
        </section>
    );
};

export default TrainingPage;
