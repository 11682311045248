/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import aboutImg from '../assets/images/minified/jill-oh-who-me.jpeg';

const aboutText = [
    'Jill is a fitness professional with a passion for body awareness and the interrelated connection between the physical body and the mind. Jill believes health comes from within and enhances the outer.',

    'As a wellness and fitness trainer, her main priority is offering a variety of exercise modalities to ensure fitness activity for all levels. By including balance, core, cardiorespiratory, flexibility, plyometric, and strength training into her programs, it invites her clients physical body to the workout and your brain, too. Integrating her yoga background into her fitness coaching offers a mental challenge within the workout. This allows clients to tap into their own strengths and weaknesses and adapt as needed. Her mission is to keep you engaged mentally as well as learn whats best for YOUR body through individualized guidance.',

    'Jill uses an open communication and discipline-based approach, to effectively guide groups and clients to achieve their desired goals. Her approach offers transparency and self guided learning with roots stemming from holistic living. She encourages those around her to start looking at their body as “one whole” instead of separate parts joined together by bones and ligaments. Jill emphasizes the close relationships between your emotional body, nutrition, and daily movement. She believes everyone should have the opportunity to move freely within their bodies.'];

const About = () => {
    return (
        <section>
            <Box>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{ mt: { md: 10 } }}>
                        <Box sx={{ m: { xs: 2, md: 5 } }} className='center'>
                            <img src={aboutImg} alt="standing outside pose" height="auto" width="80%" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ m: { xs: 2, md: 5 }, mt: { xs: -1, md: 0 } }} height='100%'>
                            <Box className='vcenter'>
                                <Typography fontSize={48} fontFamily='var(--heading-family)'>
                                    {'About Jill'}
                                </Typography>
                                {aboutText.map((paragraph) => {
                                    return (
                                        <Box key={Math.random()} p={2}>
                                            <Typography fontSize={20} mt={2}>
                                                {paragraph}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default About;
