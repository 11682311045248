import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
    About,
    Home,
    Contact,
    Consultation,
    Training,
    NotFound
} from '../pages';
import ScrollToTop from '../assets/scrollToTop';
import NavbarBar from './Navbar';
// import { useLocation } from 'react-router';
// import RouteChangeTracker from './RouteChange';

const RoutePoints = () => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            {/* <RouteChangeTracker /> */}
            <ScrollToTop />
            <main className="App">
                <NavbarBar />
                <Routes>

                    <Route exact path="/" element={<Home />}></Route>
                    <Route path="about" element={<About />}></Route>
                    <Route path="consultation" element={<Consultation />}></Route>
                    <Route path="training" element={<Training />}></Route>
                    <Route path="contact" element={<Contact />}></Route>
                    <Route
                        path="*"
                        element={<NotFound />}
                    ></Route>
                </Routes>
            </main>
        </Router>
    );
};

export default RoutePoints;
