import AppRoutes from './components/Routes.jsx';
import Footer from './components/Footer';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ec579f'
        },
        secondary: {
            main: '#F6C0DB'
        }
    }
});


const App = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <AppRoutes />
                <Footer />
            </ThemeProvider>
        </>
    );
};

export default App;
