/* eslint-disable no-unused-vars */
import { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';


const ConsultationPage = () => {
    const form = useRef();
    const [verified, setVerified] = useState(false);
    const onVerify = () => {
        setVerified(true);
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        try {
            const formData = new FormData(form.current).entries();

            await fetch('/.netlify/functions/email-consultation', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
                body: JSON.stringify(Object.fromEntries(formData))
            });

            Swal.fire({
                customClass: {
                    title: 'swal2-title'
                },
                title: 'Message Sent!',
                text: 'Thank you for your message! We will get back to you shortly you via email',
                icon: 'success',
                confirmButtonText: 'Ok!'
            }).then(() => {
                location.reload();
            });
        } catch (error) {
            Swal.fire({
                customClass: {
                    title: 'swal2-title'
                },
                title: 'Whoops!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        e.target.reset();
    };
    return (
        <section>
            <Box xs={12}>
                <Box className='center'>
                    <h1>
                                Consultation Form
                    </h1>
                </Box>
                <form
                    ref={form}
                    onSubmit={sendEmail}
                    className='vcenter'
                    display='flex'
                    style={{ flexDirection: 'column', width: '100%' }}
                >
                    <Grid container alignItems="center" justify="center" direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Name"
                                type="text"
                                autoComplete='name'
                                placeholder='First name, Last name'
                                required
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="email"
                                label="Email"
                                type="text"
                                autoComplete='email'
                                placeholder='someEmail@example.com'
                                required
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="birthday"
                                label="Birthday"
                                type="text"
                                placeholder='mm/dd/yyyy'
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}>
                                <FormControl fullWidth>
                                    <InputLabel required>Gender</InputLabel>
                                    <Select
                                        MenuProps={{
                                            disableScrollLock: true
                                        }}
                                        labelId="gender-label"
                                        id="gender-select"
                                        name='gender'
                                        label="Gender"
                                        defaultValue={''}
                                    >
                                        <MenuItem value={'Female'}>Female</MenuItem>
                                        <MenuItem value={'Male'}>Male</MenuItem>
                                        <MenuItem value={'I\'d rather not say'}>{'I\'d rather not say'}</MenuItem>
                                        <MenuItem value={'other'}>{'Other'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="goals"
                                label="Desired Goals"
                                type="text"
                                multiline
                                required
                                rows={3}
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}>
                                <FormControl fullWidth>
                                    <InputLabel required>{'How frequently do you workout?'}</InputLabel>
                                    <Select
                                        MenuProps={{
                                            disableScrollLock: true
                                        }}
                                        labelId="workout-label"
                                        id="demo-simple-select"
                                        name='workoutFrequency'
                                        label="How frequently do you workout?"
                                        defaultValue={''}
                                    >
                                        <MenuItem value={'1-3 times per week'}>1-3 times per week</MenuItem>
                                        <MenuItem value={'3-5 times per week'}>3-5 times per week</MenuItem>
                                        <MenuItem value={'5+ times per week'}>{'5+ times per week'}</MenuItem>
                                        <MenuItem value={'None'}>{'None'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { xs: '70vw', md: '30vw' } }} border={1} borderColor='lightgray' borderRadius={'4px'}>
                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">What is your biggest concern right now?</FormLabel>
                                    <FormHelperText>Select all that apply</FormHelperText>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="biggestConcern1" value='weight loss' />
                                            }
                                            label="Weight Loss"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="biggestConcern2" value="flexibility/mobility" />
                                            }
                                            label="Flexibility/Mobility"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="biggestConcern3" value="building strength" />
                                            }
                                            label="Building Strength"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="biggestConcern4" value="toning" />
                                            }
                                            label="Toning"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { xs: '75vw', md: '35vw' } }}>
                                <FormControl fullWidth>
                                    <InputLabel required>{'Service'}</InputLabel>
                                    <Select
                                        MenuProps={{
                                            disableScrollLock: true
                                        }}
                                        labelId="service-label"
                                        id="demo-simple-select"
                                        name='service'
                                        label="service"
                                        defaultValue={''}
                                    >
                                        <MenuItem value={'Online training for Fitness, Flexibility, or Yoga'}>Online training</MenuItem>
                                        <MenuItem value={'In-person training'}>In-person training</MenuItem>
                                        <MenuItem value={'Both'}>Both</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="budget"
                                label="Monthly Budget"
                                type="text"
                                required
                                rows={3}
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="previousTraining"
                                label="What types of programs/trainings have you done in the past?"
                                type="text"
                                placeholder='Ex. Twice a week in person trainer for x years'
                                multiline
                                rows={3}
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { xs: '75vw', md: '35vw' } }}>
                                <FormControl fullWidth>
                                    <InputLabel required id='video-pref-label'>{'Are you open to a video assessment?'}
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            disableScrollLock: true
                                        }}
                                        labelId="video-pref-label"
                                        id="demo-simple-select"
                                        name='videoPreference'
                                        label="Are you open to a video assessment?"
                                        defaultValue={''}
                                    >
                                        <MenuItem value={'Yes we can video chat.'}>Yes we can video chat.</MenuItem>
                                        <MenuItem value={'No I\'d prefer an over the phone call'}>{'No I\'d prefer an over the phone call.'}</MenuItem>
                                    </Select>
                                    <FormHelperText sx={{ maxWidth: { xs: '75vw', md: '35vw' } }}>Video call will eventually be required in order for me to assess your current fitness/flexibility level.</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="availability"
                                label="Availability"
                                type="text"
                                placeholder='Ex. Monday @ 8:30 AM'
                                required
                                helperText='List 2 dates/times that you are available for a 45 minute video assessment'
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { xs: '75vw', md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { xs: '75vw', md: '35vw' } }}>
                                <FormControl fullWidth>
                                    <Box mb={2}>
                                        <Typography align='center'>{'On a scale of 1-5, how committed are you to making a change right now?'}</Typography>
                                    </Box>
                                    <Select
                                        MenuProps={{
                                            disableScrollLock: true
                                        }}
                                        labelId="video-pref-label"
                                        id="demo-simple-select"
                                        name='commitmentLevel'
                                        defaultValue={'5 - Let\'s go!'}
                                    >
                                        <MenuItem value={'1 - Not at all'}>1 - Not at all</MenuItem>
                                        <MenuItem value={'2 - Maybe a little'}>{'2 - Maybe a little'}</MenuItem>
                                        <MenuItem value={'3 - More than a little'}>{'3 - More than a little'}</MenuItem>
                                        <MenuItem value={'4 - For Sure'}>{'4 - For Sure'}</MenuItem>
                                        <MenuItem value={'5 - Let\'s go!'}>{'5 - Let\'s go!'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Reaptcha
                                sitekey="6LcvlTckAAAAAMraM3ShBnfAZsu1eg0PcdRELM-J"
                                onVerify={onVerify}
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                size='large'
                                variant='outlined'
                                color='primary'
                                disabled={!verified}
                                sx={{ minWidth: { xs: '75vw', md: '35vw' }, maxWidth: { md: '35vw' } }}
                            >
                                        Send
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>

        </section>
    );
};

export default ConsultationPage;
