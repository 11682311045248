/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import AboutPage from '../pages/About';
import splashImg from '../assets/images/minified/jill-half-lunge-sq.jpeg';

const Home = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 900;

    return (
        <section>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <Box m={5}>
                        <img src={splashImg} alt="jill side lunge square" height="auto" width="100%"/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}
                    sx={{ mt: { xs: 4, sm:8, md: 0 } }}
                >
                    <Box
                        sx={{ m: { xs: 1 }, mt: { xs: -12, md: 0 }, pb: { xs: 8, md: 0 } }}
                        className='vcenter'
                        id={isMobile ? 'ornament-sq' : 'ornament'}
                    >
                        <Box sx={{ mt:{ xs: 10, lg: 0 } }}>
                            <Typography
                                align='center'
                                fontFamily={'var(--heading-family)'}
                                fontSize={{ xs: 34, md: 30, lg: 44, xl: 52 }}
                            >
                                {'Training With Jill'}
                            </Typography>
                            <Typography
                                align='center'
                                fontFamily={'var(--heading-family)'}
                                fontSize={{ xs: 22, md: 22, lg: 30, xl: 38 }}>
                                {'Balanced Mind, Body, and Soul'}
                            </Typography>
                        </Box>
                        <Box mt={6}>
                            <Link to='/consultation'>
                                <Button variant='outlined' size='large'>
                                    {'Schedule a session!'}
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Grid>
                <Grid item width={'100%'} xs={12} sx={{ backgroundColor: '#D1BB80' }} mt={8}>
                    <Box className='center' m={2} fontSize={{ xs: 24, md: 32 }} sx={{ textAlign: 'center' }}>
                        {'“Take care of your mind, your body will thank you. Take care of your body, your mind will thank you.”'}
                    </Box>
                    <Box fontSize={{ xs: 26, md: 32 }} className='center' mt={5} pb={8}>
                        {'- Debbie Hampton'}
                    </Box>
                </Grid>
            </Grid>
            <AboutPage />
        </section>
    );
};

export default Home;
